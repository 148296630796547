import Text from "@/components/LanguageResolver";
import Link from "next/link";

export default function Home() {
  return (
    <div className="dummy">
      <h1><Text val="index.greeting" /></h1>
      <h2><Text val="index.description" /></h2>
      <span className="vert"></span>
      <Link href='/play'>
        <p>Examples</p>
      </Link>
      <span className="vert"></span>
      <Link href='/user/profile'>
        <p>User system</p>
      </Link>
    </div>
  )
}
